import { render, staticRenderFns } from "./TaskSetting.vue?vue&type=template&id=a29e25e8&"
import script from "./TaskSetting.vue?vue&type=script&lang=js&"
export * from "./TaskSetting.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports